import React,{ useState, useEffect }  from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'

const Redirect = () => {

    const [ctaLink, setLink] = useState(false);
    const cemberLink = "cember://me"
    useEffect(() => {
      let ctaLink = "/invite"
        if (window.navigator.userAgent.match(/Android/i)) {
           ctaLink="https://play.google.com/store/apps/details?id=ist.tio.cember"
        }else if (
          window.navigator.userAgent.match(/iPhone/i) ||
          window.navigator.userAgent.match(/iPad/i) 
        ) {
           ctaLink="https://apps.apple.com/us/app/id1532621504"
        }else{
           ctaLink="/invite"
        }
        setLink(ctaLink);
     
        
        
        
    }, []);

    useEffect(() => {
        setTimeout(function () { window.location = ctaLink; }, 25);
        window.location.href = cemberLink  
      }, [ctaLink]);

   
    


    return (
        <Layout>
            <Head title="Çember" />
            <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
             
               
              </div>
            </div>
            <div className="col-6 center">
          
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default Redirect